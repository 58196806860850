import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/menu.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'menu',
    component: () => import('../views/menu.vue')
  },
  {
    path: '/car',
    name: 'car',
    component: () => import('../views/car.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
