<template>
  <div id="app">
    <router-view class="routerView" />
    <div id="nav">
      <div :class="routeName == 'menu' && 'active'"
        @click="openPage('/',0)">
        <i class="iconfont icon-liebiaocaidan"></i>
        <div class="nav-title">菜单</div>
      </div>
      <div :class="routeName == 'car' && 'active'"
        @click="openPage('/car',1)">
        <i class="iconfont icon-gouwuche-tianchong"></i>
        <div class="nav-title">购物车</div>
      </div>
      <div :class="routeName == 'order' && 'active'"
        @click="openPage('/order',2)">
        <i class="iconfont icon-lishi1"></i>
        <div class="nav-title">已点</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  computed: {
    routeName() {
      return this.$route.name
    }
  },
  methods: {
    openPage(path, num) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss">
html {
  height: 100%;
  body {
    margin: 0;
    height: 100%;
  }
}
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 6rem;
  width: 100%;
  box-shadow: 0px 0px 15px -7px #999;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #999;
  .active {
    color: cornflowerblue;
  }
  .iconfont {
    font-size: 3rem;
  }
  .nav-title {
    font-size: 1.4rem;
  }
}
</style>
