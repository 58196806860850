import Vue from 'vue'
import store from '@/store'
import axios from 'axios'

const request = axios.create({

})

request.interceptors.request.use(
  config => {
    // config.headers = {
    //   token: '',
    //   Authorization: '',
    //   ...config.headers
    // }

    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// request.interceptors.response.use(
//   res => {
//     if (res.data.suc && res.data.msg) {
//       // success
//       Message.success(res.data.msg)
//     } else if (res.data.msg && res.data.msg !== 'ok') {
//       // fail
//       Message(res.data.msg)
//     }

//     if (res.data.code == 1) return res.data
//     return Promise.reject(res.data)
//   },
//   err => {
//     // 登录过期
//     if (err.response && err.response.status === 900) {
//       Message.warning('请重新登陆')
//       store.commit('LOG_OUT')
//     } else {
//       Message.error('加载失败')
//     }

//     return Promise.reject(err)
//   }
// )

Vue.prototype.$http = request

export default request
